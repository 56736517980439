/* Ensure body has full height */
body, html {
    height: 100%;
    margin: 0;
  }
  
  /* Main layout styling */
  .dashboard-main {
    display: flex;
    min-height: 100vh; /* Ensure the layout takes full viewport height */
    background-color: white;
  }
  
  /* Sidebar styling */
  .sidebar {
    width: 250px; /* Fixed width for the sidebar */
    position: fixed;
    top: 0;
    left: 0;
    height: 100%; /* Full height */
    background-color: #f8f9fa; /* Or any color you prefer */
    overflow-y: auto; /* Enable scrolling if the content is too long */
    padding-top: 20px;
  }
  
  /* Main content styling */
  .main-content {
    padding: 20px;
    padding-left: 250px; /* Should match the sidebar width */
    margin-left: 25px;
    flex-grow: 1; /* Take up the remaining space */
    display: block !important;
    transition: all 0.5s ease;;
  }

  /* Collapse sidebar */
.toggle-sidebar .main-content {
  padding-left: 0px;
  transition: all 0.5s ease;
}

td.result_false
{
  color:red;
}
td.result_true{
  color:green;
}
  
.sidebar-nav .nav-content a.active {
  color: #cc1616 !important;
}