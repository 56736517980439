.due-now {
    background-color: rgba(255, 0, 0, 0.1); /* Light red */
  }

 tr.due-now:nth-of-type(odd)>* {
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: rgb(236, 69, 69,0.2) !important;
 }
 tr.due-now:nth-of-type(even)>* {
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: #ec45451a!important;
 }


 .safe-now {
   background-color: rgba(60, 255, 0, 0.1); /* Light red */
 }

tr.safe-now:nth-of-type(odd)>* {
   --bs-table-color-type: var(--bs-table-striped-color);
   --bs-table-bg-type: rgba(60, 255, 0,0.2) !important;
}
tr.safe-now:nth-of-type(even)>* {
   --bs-table-color-type: var(--bs-table-striped-color);
   --bs-table-bg-type: rgba(60, 255, 0, 0.1)!important;
}

tr.renewed:nth-of-type(odd)>* ,tr.renewed:nth-of-type(even)>*
{
   --bs-table-color-type: var(--bs-table-striped-color);
   --bs-table-bg-type: rgb(32, 128, 3)!important;
   color: white;
}
.due-today {
  background-color: #ffd700 !important;
}