.latestMessage
{
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 250px;
    white-space: nowrap;
}

.unread
{
    font-weight: bold;
}

.senders
{
    height: 70vh;
    overflow: scroll;
}

.senderButton
{
    cursor: pointer;
    cursor: pointer;
    border-top: solid 1px lightcoral;
    background-color: rgba(240, 128, 128, .01);
}
.senderButton .unread
{
    font-weight: bold;

}
.senderButton.selected
{
    font-weight: bold;
    background-color: rgba(240, 128, 128, .1);

}

.recipient
{
    height:50px;
    text-align:right;
}
.recipient img
{
    max-height: 50px;;
}
.senderPhone
{
    font-size:12px;
}
.messages-parent
{
    height:70vh;
}
.messages
{
    background-color: rgba(240, 128, 128, .05);
    min-height: 450px;
    height: 92%;
    padding: 10px;
}

.message
{
    font-size:12px;
}
.message-date
{
    font-size: 8px;;
}
.message-status
{
    font-size: 8px;
    justify-content: flex-end !important;

}
.message-text
{
    white-space: pre-wrap;
}

.message-image
{
    max-height: 300px;
}
.message-reaction-sent
{
    background: unset !important;
    z-index: 10;
    margin-bottom: -10px;
    margin-left: -20px;
    padding: 2px;
    border-radius: 5px;
    right: 10px;
    display: flex;
    justify-content: flex-end;
    bottom: 10px;
    flex-direction: column;
    flex-wrap: nowrap;
}
.message-reaction-received
{
    background: unset !important;
    z-index: 10;
    margin-bottom: -10px;
    margin-left: -20px;
    padding: 2px;
    border-radius: 5px;
    right: 10px;
    display: flex;
    justify-content: flex-end;
    bottom: 10px;
    flex-direction: column;
    flex-wrap: nowrap;
}
.sent
{
    justify-content: flex-end !important;
}
.sent > div{
    background-color: rgb(253, 222, 222);
    max-width: 70%;

}
div.message-metadata
{
    text-align: right;
    max-width: 100% !important;
}
.received >div
{
    background-color: rgb(240, 240, 240);
    max-width: 100%;
}



.reaction-popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    z-index: 1000;
  }
  
  .reaction-popup-content {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .reaction-popup h4 {
    margin-bottom: 8px;
  }
  
  .reaction-popup ul {
    list-style-type: none;
    padding: 0;
  }
  
  .reaction-popup li {
    margin-bottom: 4px;
  }


div.reaction {
    display: none !important;
}